import { useEffect, useRef } from 'react';

export function useUnmount(fn: Function) {
  const fnRef = useRef<Function>(fn);
  fnRef.current = fn;
  useEffect(
    () => () => {
      // if (fnRef.current) {
      //   fnRef.current();
      // }
      try {
        if (fnRef.current) {
          fnRef.current();
        }
      } catch (error) {}
    },
    []
  );
}

export function useMount(fn: Function) {
  useEffect(() => {
    fn();
  }, []);
}
