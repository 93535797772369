import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import config from '../config/config.json';
import axios from 'axios'; // Import axios

const firebaseConfig = {
  apiKey: 'AIzaSyC6mvK61qR57JZMSDL12HvNvyz3_b46r20',
  authDomain: 'milav-ctc.firebaseapp.com',
  databaseURL: 'https://milav-ctc-default-rtdb.firebaseio.com',
  projectId: 'milav-ctc',
  storageBucket: 'milav-ctc.appspot.com',
  messagingSenderId: '293032468350',
  appId: '1:293032468350:web:98397bc3585fd8e08f5d2f',
  measurementId: 'G-FL91BXTSX0'
};

const sendTokenToBackend = async (userId, fcmToken) => {
  try {
    const response = await axios.post(
      //'https://us-central1-milav-ctc.cloudfunctions.net/saveCallerDetails',
      `${config.basePath}/saveCallerDetailsV2`,
      {
        callerId: userId,
        fcmToken: fcmToken,
        deviceFingerprint: navigator.userAgent // User-Agent string
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer abcdefg' // replace 'abcdefg' with your actual token
        }
      }
    );
    console.log('Token sent to backend successfully', response.data);
  } catch (error) {
    console.error('Failed to send FCM token to backend', error.response?.data || error.message);
  }
};

export const requestNotificationPermission = async () => {
  const permission = await Notification.requestPermission();
  if (permission !== 'granted') {
    console.log('Notification permission not granted');
  }
};

export const handlePushNotifications = async (userId) => {
  navigator.serviceWorker
    .register('/firebase-messaging-sw.js')
    .then((registration) => {
      const messaging = getMessaging();
      getToken(messaging, { serviceWorkerRegistration: registration })
        .then((token) => {
          console.log('Token:', token);
          sendTokenToBackend(userId, token);
          // Send the token to your server here
        })
        .catch((error) => {
          console.error('Failed to get token', error);
        });
    })
    .catch((error) => {
      console.error('Service worker registration failed', error);
    });
};

const onForegroundMessage = () => new Promise((resolve) => onMessage(messaging, (payload) => resolve(payload)));
export const receiveForegroundMessage = () => {
  onForegroundMessage()
    .then((payload) => {
      console.log('Received foreground message: ', payload);
      const notificationTitle = payload.notification.title;
      console.log('payload', payload);
      const notificationOptions = {
        body: payload.notification.body,
        icon: '../../public/logo.svg',
        data: { url: payload.fcmOptions.link } // Assuming the URL is sent in click_action
      };
      console.log('notificationOptions', notificationOptions);
      self.registration.showNotification(notificationTitle, notificationOptions);
      self.addEventListener('notificationclick', (event) => {
        const notification = event.notification;
        const clickActionUrl = notification.data.url; // Assuming the URL is in the data property

        event.waitUntil(
          window
            .open(clickActionUrl, '_blank') // Open in a new tab/window
            .then(() => notification.close()) // Close notification after opening the URL
        );
      });
      // self.addEventListener('notificationclick', (event) => {
      //   const notification = event.notification;
      //   const clickActionUrl = notification.data.url; // Assuming the URL is in the data property

      //   event.waitUntil(
      //     clients
      //       .matchAll({ type: 'window' }) // Search for all open windows
      //       .then((matchedClients) => {
      //         // Regardless of matching origin, open the URL in a new tab
      //         return clients.openWindow(clickActionUrl, { focus: true, target: '_blank' });
      //       })
      //       .then((client) => {
      //         if (client) {
      //           notification.close(); // Close the notification after successful opening
      //         }
      //       })
      //   );
      // });
    })
    .catch((err) => console.log('An error occured while retrieving foreground message. ', err));
};
const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);
export const db = getDatabase(app);
