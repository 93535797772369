import { Fragment, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import './thankYouPage.scss';
import Butterfly from './assets/Butterfly.jpg';
import downloadGApp from '../user/assets/downloadApp_img_GApp_Store.png';
import downloadAppleApp from '../user/assets/downloadApp_img_Apple_App_Store.png';
import { handlePushNotifications } from '../../utils/firebase';

interface LocationState {
  isRejected?: boolean; // Adjust the type based on your actual state variable
  isCallNotAnswered?: boolean;
}

function ThankYouPage() {
  const location = useLocation<LocationState>();
  const isRejected = location.state?.isRejected;
  const isCallNotAnswered = location.state?.isCallNotAnswered;
  //console.log('rejected status', isRejected);
  const [osDetails, setOsDetails] = useState(null);
  const [timerLeft, setTimerLeft] = useState(10);
  useEffect(() => {
    //console.log(osDetails)
    generateDeviceFingerprint();
    //if (osDetails) redirectToDownloadApp();
    const handleBack = (event: any) => {
      event.preventDefault();
      event.stopPropagation();
      return false;
    };

    window.history.pushState(null, '', window.location.pathname);

    window.addEventListener('popstate', handleBack);

    return () => {
      window.removeEventListener('popstate', handleBack);
    };
  }, [osDetails]);

  useEffect(() => {
    const timerId = setTimeout(() => {
      window.location.href = '/'; // Redirect to homepage
      // Alternatively, display a message:
      // alert('Tab closed automatically!');
    }, timerLeft * 1000);

    // Cleanup function to clear timer on unmount
    return () => clearTimeout(timerId);
  }, [timerLeft]); // Re-run effect when timeLeft changes

  function generateDeviceFingerprint(): string {
    let osDetails: any;
    const { screen, navigator } = window;
    const userAgent = window.navigator.userAgent; // Use type assertion
    // Check for Windows
    console.log(userAgent);

    if (userAgent.indexOf('Windows') !== -1) {
      osDetails = 'Windows';
      setOsDetails(osDetails);
      console.log('Operating System: Windows');
    }
    // Check for macOS
    else if (userAgent.indexOf('Macintosh') !== -1) {
      osDetails = 'macOS';
      setOsDetails(osDetails);
      console.log('Operating System: macOS');
    } else if (userAgent.indexOf('iPhone') !== -1) {
      osDetails = 'macOS';
      setOsDetails(osDetails);
    } else if (userAgent.indexOf('Android') !== -1) {
      osDetails = 'android';
      setOsDetails(osDetails);
    } else {
      osDetails = 'unknown';
      setOsDetails(osDetails);
    }

    const fingerprint = `${userAgent} - ${screen.width}x${screen.height} - ${osDetails}`;
    return fingerprint;
  }
  const handleHearBack = () => {
    handlePushNotifications(localStorage.getItem('cid'));
  };
  const redirectToDownloadApp = () => {
    generateDeviceFingerprint();
    console.log(osDetails);

    if (osDetails == 'android') {
      window.location.href = 'market://details?id=com.callqr';
    } else if (osDetails == 'macOS') {
      window.location.href = 'https://apps.apple.com/us/app/google-maps/id585027354';
    }
  };

  return (
    <div className="container">
      <div className="butterflyImg">
        <div>This tab will close in {timerLeft} seconds.</div>
        <img src={Butterfly} alt="Thank You" height={300} width={300} />
        <p style={{ padding: '10px 0px 0px 0px' }}>Feel the butterfly effect of spreading the love.</p>
        {isRejected && (
          <Fragment>
            <p style={{ padding: '5px 0px 0px 0px' }}>Call has been Rejected.</p>
            <button className="btnHearBack" onClick={handleHearBack}>
              Hear Back
            </button>
          </Fragment>
        )}
        {isCallNotAnswered && (
          <Fragment>
            <p style={{ padding: '5px 0px 0px 0px' }}>The call was not answered.</p>
          </Fragment>
        )}
      </div>
      <div className="containerThankYouPage">
        <div className="directToAppDownloadContainer">
          <h5 style={{ color: 'white' }}>
            Thank you for using <span style={{ fontSize: '18px', color: 'red' }}>CallQR</span>
          </h5>
          <img
            onClick={redirectToDownloadApp}
            src={osDetails == 'android' ? downloadGApp : downloadAppleApp}
            alt="QR"
            height={50}
            width={150}
          />
        </div>
      </div>
    </div>
  );
}

export default ThankYouPage;
